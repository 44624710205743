.header{
    width: 100;
    background: var(--primary);
    display: flex;align-items: center;justify-content: space-evenly;
    p{
        padding-top: 10px;
        color: #fff;
        text-align: center;
        font-weight: normal;
        font-family: sans-serif;
    }
}