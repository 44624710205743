.home_container{
    width: 100%;
    min-height: 100vh;
    display: flex;justify-content: start;align-items: center; flex-direction: column;
    .logo{
        margin-block:15px ;
    }
    .form_container{
        width: 100%;
        display: flex;justify-content: center;align-items: center;flex-direction: column;
    }
}