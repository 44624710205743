  .sc-hKwDye,.card-body div nav{
        background-color:var(--bg) !important;
        color: var(--text) !important;
       }
       .glass{
       /* Hmcd */
       .sc-dkPtRN .sc-hKwDye .sc-crHmcD{
        background-color:transparent;
       }
       
       /* Ptrn */
       .sc-bdvvtL .sc-gsDKAQ .sc-dkPtRN{
        background-color:transparent;
       }
       
       /* Card */
       #root .card{
        transform:translatex(0px) translatey(0px);
       }
       
       /* Bdvvtl */
       .sc-dlVxhl .sc-fKVqWL .sc-bdvvtL{
        background-color:transparent;
       }
      
       
       /* Row 12 */
       .rdt_TableRow{
        background-color:transparent !important;
       }
       
       
       }

