:root {
    --primary:#4E8075;
    --secondary : #ffd12e;
    --text: black;
    --bg: #fafafa;
    --bg2:  #f8f8f8;
    --shadow: rgba(0, 0, 0, 0.15);
    --gray : rgb(207, 210, 214);
}

:root:has(.dark) {
    --primary: #7a987c;     
    --secondary: #ffd12e;   
    --text: #ffffff;        
    --bg: #1f1f1f;          
    --bg2: #2c2c2c;         
    --shadow: rgba(255, 255, 255, 0.15); 
    --gray: #a0aec0;  



}
