.SingleForm{
    display: flex;justify-content: start;align-items: end;
    width: 24rem;
    padding: 10px 30px;
    margin-block: 8px;
    border-radius: 7px;
    box-shadow: 1.5px 1.5px 3px 3px var(--primary);
        img{
            object-fit: contain;
        }
        .link{
            p{
                margin-left: 65px;
                font-size: 24px;
                font-weight: normal !important;
                text-transform: capitalize;
                font-family: serif;
            }
        }
}