html,body {
    background: var(--bg)

}

*{
    padding: 0;
    margin: 0;
}

a {
    color: inherit;
    font-weight: bold;
    text-decoration: none !important;
    cursor: pointer;

}

button,
svg {
    cursor: pointer;
}



.not_foound_page{
    background: black;
    height: 100vh;
    display: flex;justify-content: center;align-items:center;


    p{
        color: white;
        display: inline;
    
    }
    h6{
        display: inline;
        margin-inline:20px;
        height: 140px !important;
        
        width: 20px;
    }
    .container-not-found{
        display: flex;
        flex-direction: column;
        align-items: center;
        div{
            display: flex;justify-content: center;align-items: center;

        }
        button{
            border: none;
            outline: none;
            padding: 8px;
            border-radius: 10px;

            margin-inline:30px;
        }
    }
}